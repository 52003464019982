import { ArrowLeftIcon } from "@chakra-ui/icons";
import {
  Table,
  Tbody,
  Text,
  Th,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Thead,
  Flex,
  Button,
  Badge,
  Tr,
  Td,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,
  Spinner,
  TagLeftIcon,
  Image,
  background
} from "@chakra-ui/react";
import axios from "axios";
import { EyeIcon } from "components/Icons/Icons";
import React, { useState, useEffect } from "react";
import { parseMultilingualText } from "utils";

export default function ScanModal(props) {
  const { isOpen, onClose, data } = props;
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
const [viewTag, setViewTag] =useState("")
  /*   const fetchScans = async function () {
    //setScans(props.data);
    await axios
      .get(`/scan?all=true&companyid=${company}`)
      .then((res) => {
        var result = [];
        for (let i = 0; i < res.data.length; i++) {
          var data = flattenObject(res.data[i]);
          result.push(data);
        }
        const cityScans = [...new Set(result.flat())].filter(
          (item) => item.location.city === selectedCity
        );
        setScans(cityScans);
      })
      .catch((e) => {
        console.log(e.message);
      });
  }; */

  /*  useEffect(() => {
    fetchScans();
  }, [company, selectedCity]); */








  const [productPic, setProductPic] = useState();

const [tag, setTag] = useState();
const [scans, setTotalScans] = useState([]);
const [flag, setFlag] = useState(0);
const [found, setFound] = useState(false);
const [loading, setLoading] = useState(false);
const [dist, setDist] = useState();


const flattenObject = (object) => {
  const ipAddress = object.ipAddress;
  const location = object.location;
  const createdAt = object.createdAt;

  const result = [];

  for (let i = 0; i < ipAddress.length; i++) {
    const flattenedObject = {
      ipAddress: ipAddress[i],
      location: location[i],
      companyId: object.companyId,
      createdAt: Array.isArray(createdAt[i]) ? createdAt[i][0] : createdAt[i],
      id: object.id,
      tagId: object.tagId,
      productId: object.productId,
    
    };

    result.push(flattenedObject);
  }

  return result;
};



useEffect(() => {
  setLoading(true);

  const getTag = async () => {

await  axios
      .get(`/tag?tag=${viewTag}`)
      .then(async (res) => {
        setTag(res.data);




   axios
          .get(
            `/scan?tag=${viewTag}&company=${res.data.tag.companyId}&getAllTagScans=true`
          )
          .then(async (res) => {
              if(Object.keys(res.data).length != 0){
            var result = [];
           setFlag(res.data.flag ? res.data.flag : 0)
            var data = flattenObject(res.data);
            result.push(data);
            setTotalScans([...new Set(result.flat())]);
     
              }
       
              setFound(true);
            setLoading(false);
              

          })
          .catch((e) => {
            setFound(false);
            setLoading(false);
          });

    
      })
      .catch((e) => {
        setFound(false);
        setLoading(false);
      });
  }
  getTag();
  for (var i=0;i<data.length;i++){
if (data[i].tagId==viewTag){
  setDist(data[i].distributorName)
}
  }
}, [viewTag]);

useEffect(() => {

if(tag && tag.product.productId){

 axios
         .get(`/s3?key=troofmedia/${tag?.product?.pictures[0]}`)
         .then((res) => {
           setProductPic(res.data);
         }).catch((e) => {
   
         });
     
      }

  },[tag]) 

  return (
    <>{ viewTag =="" ? 
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      size={"6xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Scanned Tags</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={0} overflowX={{ sm: "scroll" }}>
          <Table
            variant="simple"
            color={textColor}
            style={{ marginTop: "10px" }}
          >
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                <Th borderColor={borderColor} color="gray.500">
                  Tag
                </Th>
                <Th borderColor={borderColor} color="gray.500">
                  Product
                </Th>
                <Th borderColor={borderColor} color="gray.500">
                  Distributor
                </Th>
                <Th borderColor={borderColor} color="gray.500">
                  Country
                </Th>
                <Th borderColor={borderColor} color="gray.500">
                  City
                </Th>
                <Th borderColor={borderColor} color="gray.500">
                  Date
                </Th>
                <Th borderColor={borderColor} color="gray.500">
                  Flag
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((row, index, arr) => {
                return (
                  <Tr>
                    <Td borderColor={borderColor}>
                      <Flex direction="row">
                        <Button size="sm" onClick={()=>setViewTag(row.tagId)}>
                      <EyeIcon />  
                      </Button>
                        <Text fontSize="sm" color={textColor} fontWeight="bold" margin={"5px"}>
                  
          {row.tagId}

      
                        </Text>
                      </Flex>
                    </Td>
                    <Td borderColor={borderColor}>
                      <Flex direction="column">
                        <Text fontSize="sm" isTruncated color={textColor} fontWeight="bold">
                          {parseMultilingualText(
                            row.productName != undefined
                              ? row.productName
                              : row.productId,
                            "en"
                          )}
                        </Text>
                      </Flex>
                    </Td>
                    <Td borderColor={borderColor}>
                      <Flex direction="column">
                        <Text fontSize="sm" isTruncated color={textColor} fontWeight="bold">
                          {row.distributorName != undefined
                            ? row.distributorName
                            : row.distributorId}
                        </Text>
                      </Flex>
                    </Td>
                    <Td borderColor={borderColor}>
                      <Flex direction="column">
                        <Text fontSize="sm" isTruncated color={textColor} fontWeight="bold">
                          {row.location.country}
                        </Text>
                      </Flex>
                    </Td>
                    <Td borderColor={borderColor}>
                      <Flex direction="column">
                        <Text fontSize="sm" isTruncated color={textColor} fontWeight="bold">
                          {row.location.city}
                        </Text>
                      </Flex>
                    </Td>
                    <Td borderColor={borderColor}>
                      <Flex direction="column">
                        <Text fontSize="sm" isTruncated color={textColor} fontWeight="bold">
                          {new Date(row.createdAt).toUTCString()}
                        </Text>
                      </Flex>
                    </Td>
                    <Td borderColor={borderColor}>
                      <Badge
                        bg={
                          row.flag == 0 || row.flag == undefined
                            ? "#fe4f10"
                            : row.flag == 2
                            ? "gray"
                            : "yellow"
                        }
                        color={status === "ok" ? "white" : "white"}
                        padding={"5px"}
                        height={"30px"}
                        marginBottom={"5px"}
                        width={"30px"}
                        borderRadius="8px"
                      ></Badge>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </ModalBody>
      </ModalContent>
    </Modal>:    <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size={"3xl"}
      >
        <ModalOverlay />
        <ModalContent>
    
          <ModalHeader> <Button onClick={()=>setViewTag("")} style={{backgroundColor:""}} > <ArrowLeftIcon  marginRight={"5px"}/> Go Back </Button>  Tag: {viewTag}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6} >

          {tag && found && !loading && <> <div className="tag-profile-card">
      <div className="tag-profile-picture">
     {productPic && (<Image
                src={`data:image/jpeg;base64,${productPic}`}
                boxSize="200px"
                objectFit="center"
                borderRadius="md"
                mr={4}
              />)}
      </div>
      <div className="profile-details" style={{ marginTop: "20px", marginBottom: "20px" }}>
  {[
    {
      label: "Creation Date : ",
      value: tag.tag?.createdAt,
    },
    {
      label: "Product : ",
      value: parseMultilingualText(tag?.product?.name) || "unknown",
    },
    {
      label: "Distributor : ",
      value: dist != undefined
      ? dist
     : "unknown",
    },
    {
      label: "Batch ID : ",
      value: tag?.batchId || "unknown",
    },
    {
      label: "Flag : ",
      value: (
        <span style={{ color: flag === 0 ? "red" : flag === 2 ? "gray" : "yellow" }}>
          {flag === 0 ? "red" : flag === 2 ? "gray" : "yellow"}
        </span>
      ),
    },
  ].map((detail, index) => (
    <div key={index} className="profile-detail">
      <span className="detail-label" style={{fontWeight:"bold"}}>{detail.label}</span>
      <span className="detail-value">{detail.value}</span>
      {index < 5 && <div style={{ borderBottom: "1px solid gray", margin: "8px 0" }} />} {/* Separator */}
    </div>
  ))}
</div>
    </div>

  <h2 style={{fontWeight: 'bold'}}>All Scans</h2>
  
            <Table
              variant="simple"
              color={textColor}
              style={{ marginTop: "10px" }}
            >
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                  <Th color="gray.500">Country</Th>
                  <Th color="gray.500">City</Th>
                  <Th color="gray.500">Timestamp</Th>
                  <Th color="gray.500">IP</Th>
                </Tr>
              </Thead>
              <Tbody>
                {scans &&
                  scans.length > 0 &&
                  scans.map((item, index) => {
                    return (
                      <Tr key={index}>
                        <Td>{item.location.country}</Td>
                        <Td>{item.location.city}</Td>
                        <Td>
                          {item.date ? item.date : item.createdAt}
                        </Td>
                        <Td>{item.ipAddress}</Td>
                      </Tr>
                    );
                  })}
                  {scans.length==0 && found && !loading && <div style={{ margin:"7px"}}>{"No scans yet..."}</div>}
              </Tbody>
            </Table> </>}
            {!found && !loading && <>OOPS TAG NOT FOUND</>}
{loading && <div style={{alignItems:"center", justifyContent:"center", display:"flex"}}><Spinner size="xl" color="gray"/></div>}
          </ModalBody>
        </ModalContent> 
      </Modal>}</>
  );
}
